import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!
  const _component_Upsert = _resolveComponent("Upsert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_List, {
      onUpsert: _ctx.onUpsert,
      onDel: _ctx.onDel,
      "widget-classes": "mb-5 mb-xl-8"
    }, null, 8, ["onUpsert", "onDel"]),
    _createVNode(_component_Upsert, {
      newsCateId: _ctx.newsCateIdModal,
      onReset: _ctx.onReset
    }, null, 8, ["newsCateId", "onReset"])
  ], 64))
}